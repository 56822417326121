import { Directive, TemplateRef, ViewContainerRef, inject, input } from '@angular/core';
import { PermissionNames } from '../entities/permission';
import { PermissionsService } from '../services/permissions.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';

@Directive({
  selector: '[libAuthDomainCanView]',
  standalone: true,
})
export class CanViewDirective {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private permissionService = inject(PermissionsService);

  canView = input<PermissionNames[] | PermissionNames>([], { alias: 'libAuthDomainCanView' });
  canViewElse = input<TemplateRef<any> | undefined>(undefined, { alias: 'libAuthDomainCanViewElse' });

  private paramsChanged$ = combineLatest([toObservable(this.canView), toObservable(this.canViewElse)]).pipe(takeUntilDestroyed());

  constructor() {
    this.paramsChanged$.subscribe(() => {
      const hasPermission = this.permissionService.hasPermission(this.canView());
      this.viewContainer.clear();
      if (hasPermission || hasPermission === undefined) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.canViewElse()) {
        this.viewContainer.createEmbeddedView(this.canViewElse() as TemplateRef<any>);
      }
    });
  }
}
