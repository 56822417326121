import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { httpPathRole, httpPathRoles, httpPathUser, httpPathUsers } from '../infrastructure/user-http-endpoints';
import { toSignal } from '@angular/core/rxjs-interop';

export interface Role {
  id: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  property_name: string;
  permission_ids: number[];
}

export interface UserReference {
  id: number;
  userid: string;
  fullname: string;
  firstname: string;
  lastname: string;
  email: string;
  status: number;
  roles: number[];
  sponsor: number | number[];
}

@Injectable({
  providedIn: 'root',
})
export class UserReferenceService {
  private apiService = inject(ApiService);

  private rolesTrigger$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  roles$ = this.rolesTrigger$.pipe(
    switchMap(() => this.apiService.get<Role[]>(httpPathRoles())),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  roleNames = toSignal(
    this.roles$.pipe(map(roles => roles.reduce((acc, role) => Object.assign(acc, { [role.id]: role.name }), {} as Record<number, string>))),
    {
      initialValue: {} as Record<number, string>,
    },
  );
  roleOptions = toSignal(
    this.roles$.pipe(
      map(roles =>
        roles.map(role => ({
          label: role.name,
          value: role.id,
        })),
      ),
    ),
    { initialValue: [] },
  );

  private usersTrigger$ = new BehaviorSubject<void>(undefined);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  users$ = this.usersTrigger$.pipe(
    switchMap(() => this.roles$.pipe(filter(Boolean), take(1))),
    switchMap(() => this.apiService.get<UserReference[]>(httpPathUsers())),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  searchUsers(params: { roles: number[] }) {
    return this.apiService.get<UserReference[]>(httpPathUsers(), { params });
  }

  // only for manual update, otherwise use this.users$
  reloadUsers() {
    this.usersTrigger$.next();
    return this.users$;
  }

  deleteUser(id: number) {
    return this.apiService.delete(httpPathUser(id));
  }

  // only for manual update, otherwise use this.roles$
  reloadRoles() {
    this.rolesTrigger$.next();
    return this.roles$;
  }

  getRole(id: number) {
    return this.apiService.get(httpPathRole(id));
  }

  createRole(doc: any) {
    return this.apiService.post(httpPathRoles(), doc);
  }

  updateRole(id: number, doc: any) {
    return this.apiService.put(httpPathRole(id), doc);
  }

  deleteRole(id: number) {
    return this.apiService.delete(httpPathRole(id));
  }

  getUsersWithRole(params: { role: number }) {
    return this.apiService.get<UserReference[]>(httpPathUsers(), { params });
  }
}
