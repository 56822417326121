/// <reference lib="webworker" />

declare const self: ServiceWorkerGlobalScope;

import { EncryptObj, ServiceWorkerEncryptor } from './service-worker-encryptor';
import { ReplayWorkerMode, sendClientMessage, ServiceWorkerCommand } from './service-worker-util';

export const ReplayWorker = {
  mode: 'disabled' as ReplayWorkerMode,
  storedResponses: [] as { urlPath: string; body: unknown; headers: Headers; status: number }[],
  replayQueue: [] as { urlPath: string; body: unknown; headers: Headers; status: number }[],
  initialUrl: '/',

  watchMessages() {
    self.addEventListener('message', async event => {
      switch (event.data.command) {
        case ServiceWorkerCommand.CLEAN_CACHE:
          ReplayWorker.storedResponses = [];
          if (this.mode !== 'record' && !this.replayQueue.length) {
            this.mode = 'disabled';
          }
          sendClientMessage(ServiceWorkerCommand.REPLAY_MODE, ReplayWorker.mode);
          break;
        case ServiceWorkerCommand.REPLAY_RECORD:
          ReplayWorker.mode = 'record';
          this.initialUrl = event.data.initialUrl || '/';
          sendClientMessage(ServiceWorkerCommand.REPLAY_MODE, ReplayWorker.mode);
          break;
        case ServiceWorkerCommand.REPLAY_STORE:
          await this.downloadReplay(event);
          ReplayWorker.mode = 'disabled';
          sendClientMessage(ServiceWorkerCommand.REPLAY_MODE, ReplayWorker.mode);
          break;
        case ServiceWorkerCommand.REPLAY_STOP:
          ReplayWorker.mode = 'disabled';
          this.replayQueue = [];
          sendClientMessage(ServiceWorkerCommand.REPLAY_MODE, ReplayWorker.mode);
          break;
        case ServiceWorkerCommand.REPLAY_LOAD:
          if (event.data.fileContent) {
            const fileLoaded = await ReplayWorker.loadFile(event.data.fileContent, event.data.password);
            if (fileLoaded) {
              console.log('Data successfully restored.');
              ReplayWorker.mode = 'play';
              sendClientMessage(ServiceWorkerCommand.REPLAY_MODE, ReplayWorker.mode);
              sendClientMessage(ServiceWorkerCommand.REPLAY_LOAD, this.initialUrl);
              break;
            }
          }
          console.error('Decryption failed. Incorrect password?');
          break;
      }
    });
  },

  async handleFetch(request: Request) {
    if (this.mode === 'disabled') {
      return false;
    }

    const urlPath = new URL(request.url).pathname;
    if (this.mode === 'record') {
      console.log('record', urlPath);
      const response = await fetch(request);
      const body = await response.json();
      this.storedResponses.push({ urlPath: urlPath, body, headers: response.headers, status: response.status });
      return false;
    }

    const responseIndex = this.replayQueue.findIndex(r => r.urlPath === urlPath);
    if (responseIndex === -1) {
      console.log('NO match', urlPath);
      return false;
    }

    console.log('match', urlPath);
    const response = this.replayQueue.splice(responseIndex, 1)[0];
    return new Response(JSON.stringify(response.body), {
      status: response.status,
      headers: { ...response.headers, 'Content-Type': 'application/json' },
    });
  },

  async downloadReplay(event: ExtendableMessageEvent) {
    const url = await this.getDownloadLink(event.data.password);
    sendClientMessage(ServiceWorkerCommand.REPLAY_STORE, url);
  },

  async getDownloadLink(password?: string) {
    const encryptedData = await ServiceWorkerEncryptor.encryptData(
      JSON.stringify({ replayQueue: this.storedResponses, initialUrl: this.initialUrl }),
      password,
    );
    const blob = new Blob([JSON.stringify(encryptedData)], { type: 'application/json' });
    return await this.blobToDataURL(blob);
  },

  async loadFile(fileContent: EncryptObj, password?: string) {
    const decryptedData = await ServiceWorkerEncryptor.decryptData(fileContent, password);
    const { replayQueue, initialUrl } = (decryptedData && JSON.parse(decryptedData)) || {};
    this.replayQueue = replayQueue || [];
    this.initialUrl = initialUrl || '/';
    console.log('Loaded Debug Data:', { initialUrl, replayQueue });
    return !!decryptedData;
  },

  blobToDataURL(blob: Blob) {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string);
    });
  },
};
