/// <reference lib="webworker" />

/* eslint-disable @typescript-eslint/naming-convention */

import { ReplayWorker } from './replay-worker';
import { ServiceWorkerCommand } from './service-worker-util';

declare const self: ServiceWorkerGlobalScope;

const pathToRegExp = (path: string) => new RegExp(path.replaceAll('/', '\\/').replaceAll('*', '([^/]+)'));

const SHORTTIME_CACHE_TIMEOUT = 2000;
// for multiple consecutive requests
const URLS_TO_CACHE_SHORTTIME_REGEX = ['api/mca/*/offers'].map(pathToRegExp);

const URLS_TO_CACHE_REGEX = [
  'api/general/venues',
  'api/user', // references users
  'api/mca/*/ar/assignment',
  'api/ar/users',
  'api/mca/reftab',
  'api/mca/attributes/collatportf/names',
  'api/mca/related/*',
  'api/mca/*/attributes/collatportf',
  'api/mca/*/settlement_attributes',
  'api/mca/*/transaction/nextpayment',
  'api/mca/*/collection/reminder/active',
  'api/mca/*/mcarefcompanies',
  'api/mca/*/creditcard',
  'api/mca/*/loadinvestorsparticipation',
  'api/mca/*/workflow',
  'api/mca/*/answers',
  'api/mca/audit/*',
  'api/mca/qarecord/*',
  'api/mca/datamerch/categories',
  'api/transaction/constants',
  'api/offers/template',
  'api/contractloan/paymentperiods',
  'api/contractloan/lineofcredits/venues',
  'api/contractloan/styles',
]
  .map(pathToRegExp)
  .concat(URLS_TO_CACHE_SHORTTIME_REGEX);

const escapePathMapToRegExp = (pathMap: Record<string, string[]>): Map<RegExp, RegExp[]> =>
  new Map(Object.entries(pathMap).map(([key, value]) => [pathToRegExp(key), value.map(pathToRegExp)]));
const CLEANUP_TRIGGERS_REGEXP_MAP = escapePathMapToRegExp({
  'api/user/*/*': ['api/user'],
  'api/ar/users/*': ['api/ar/users'],
  'api/mca/*/ar/*/assignment': ['api/mca/*/ar/assignment'],
  'api/mca/*/offers/*/*': ['api/mca/*/offers'],
  'api/mca/reftab/*/*': ['api/mca/reftab'],
  'api/mca/reftab/*': ['api/mca/reftab'],
  'api/mca/*/offers/*': ['api/mca/*/offers'],
  'api/offers/template/*': ['api/offers/template'],
});
const matchCleanupTriggers = (path: string) =>
  Array.from(CLEANUP_TRIGGERS_REGEXP_MAP.entries())
    .filter(([trigger]) => trigger.test(path))
    .map(([_, triggers]) => triggers)
    .flat();

const ongoingRequests = new Map<string, PromiseLike<Response>>();

export const startServiceWorker = (cacheName: string) => {
  const cleanCache = () =>
    caches.delete(cacheName).then(() => {
      console.log('Service Worker: Cache Cleaned');
    });

  const cleanCacheByUrlPath = (path: string | RegExp) =>
    caches.open(cacheName).then(cache =>
      cache.keys().then(keys => {
        const requests =
          typeof path === 'string'
            ? keys.filter(key => new URL(key.url).pathname.startsWith(path))
            : keys.filter(key => new RegExp(path).test(key.url));
        return requests.length ? Promise.all(requests.map(r => cache.delete(r))) : undefined;
      }),
    );

  const isXHRorFetchRequest = (request: Request) => {
    if (!request.url.includes('/api/')) return false;
    return request.headers.get('X-Requested-With') === 'XMLHttpRequest' || request.destination === '';
  };

  async function handleFetchRequest(event: FetchEvent) {
    const replayResponse = await ReplayWorker.handleFetch(event.request.clone());
    if (replayResponse) {
      return replayResponse;
    }

    const { request } = event;
    const urlPath = new URL(request.url).pathname;

    if (request.method !== 'GET') {
      matchCleanupTriggers(urlPath).forEach(m => cleanCacheByUrlPath(m));
    }

    const pathMatched = URLS_TO_CACHE_REGEX.some(regex => regex.test(request.url));
    if (!pathMatched) {
      return fetch(request);
    }

    if (request.method !== 'GET') {
      cleanCacheByUrlPath(urlPath);
      return fetch(request);
    }

    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(request);

    if (cachedResponse) {
      return cachedResponse;
    }

    const existingFetchPromise = ongoingRequests.get(request.url)?.then(response => response.clone());

    if (existingFetchPromise) {
      return existingFetchPromise;
    }

    const fetchPromise = fetch(request);

    fetchPromise
      .then(response => {
        cache.put(request, response.clone());

        const isShorttimeCache = URLS_TO_CACHE_SHORTTIME_REGEX.some(regex => regex.test(request.url));
        if (isShorttimeCache) {
          setTimeout(() => cleanCacheByUrlPath(urlPath), SHORTTIME_CACHE_TIMEOUT);
        }
      })
      .finally(() => {
        ongoingRequests.delete(request.url);
      });

    ongoingRequests.set(request.url, fetchPromise);
    return fetchPromise;
  }

  self.addEventListener('fetch', event => {
    if (!isXHRorFetchRequest(event.request)) {
      return;
    }

    event.respondWith(handleFetchRequest(event));
  });

  self.addEventListener('message', event => {
    switch (event.data.command) {
      case ServiceWorkerCommand.CLEAN_CACHE:
        if (event.data.path) {
          event.waitUntil(cleanCacheByUrlPath(event.data.path));
        } else {
          event.waitUntil(cleanCache());
        }
        break;
    }
  });

  self.addEventListener('install', () => {
    self.skipWaiting();
  });

  self.addEventListener('activate', event => {
    console.log('Service Worker: Updated');
    // clear old caches when cache name changes
    event.waitUntil(
      caches
        .keys()
        .then(cacheKeys => Promise.all(cacheKeys.map(cacheKey => (cacheKey !== cacheName ? caches.delete(cacheKey) : undefined)))),
    );
  });

  ReplayWorker.watchMessages();
};
