import { Injectable, inject, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PermissionNames } from '../entities/permission';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class MenuAccessService {
  private permissionsService = inject(PermissionsService);

  private menuItems: MenuItem[] = [];
  allowedMenuItems = signal<MenuItem[]>([]);

  setMenuItems(items: MenuItem[]) {
    this.menuItems = items;
    this.updateAllowedItems();
  }

  updateAllowedItems() {
    this.allowedMenuItems.set(this.menuItems.map(this.filterItems.bind(this)).filter((item): item is MenuItem => item !== false));
  }

  checkMenuRouteAccess(path: string): boolean {
    return this.searchByRoutePath(path, this.allowedMenuItems());
  }

  private trimSlashes(value: string) {
    return value.replace(/^\/+|\/+$/g, '');
  }

  private searchByRoutePath(routePath: string, items: MenuItem[]): boolean {
    const targetPath = this.trimSlashes(routePath);
    return items.some(item => {
      const itemPath = this.trimSlashes(item.routerLink?.join('/') ?? '');
      if (item.pathMatch === 'partial' && itemPath.indexOf(targetPath) > -1) {
        return true;
      }
      const isNavItem = (itemPath || !item.routerLink) && !item.command && !item.disabled;
      const pathMatched = targetPath.startsWith(itemPath) || itemPath.startsWith(targetPath);
      if (isNavItem && pathMatched) {
        return item.items ? this.searchByRoutePath(routePath, item.items) : true;
      }
      return false;
    });
  }

  filterItems(item: MenuItem): MenuItem | false {
    if (!this.verifyAccess(item)) {
      return false;
    }
    return {
      ...item,
      items: item.items
        ?.map(i => (Array.isArray(i) ? i.map(this.filterItems.bind(this)).filter(Boolean) : this.filterItems(i)))
        .filter(Boolean) as MenuItem[][],
    };
  }

  private verifyAccess(item: MenuItem) {
    return !item.skip?.() && (!item.permissions || this.permissionsService.hasPermission(item.permissions as PermissionNames[]));
  }
}
