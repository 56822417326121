export interface EncryptObj {
  salt: number[];
  iv: number[];
  encrypted: number[];
}

const defaultPassword = 'pynFux-4qaxse-zongug';

const SALT = crypto.getRandomValues(new Uint8Array(16));

export const ServiceWorkerEncryptor = {
  async encryptData(data: string, password = defaultPassword) {
    const key = await this.deriveKey(password, SALT);
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);
    const encryptedBuffer = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, encodedData);

    return { salt: Array.from(SALT), iv: Array.from(iv), encrypted: Array.from(new Uint8Array(encryptedBuffer)) };
  },

  async decryptData(encryptedObj: EncryptObj, password = defaultPassword) {
    try {
      const { salt, iv, encrypted } = encryptedObj;
      const key = await this.deriveKey(password, new Uint8Array(salt));
      const decryptedBuffer = await crypto.subtle.decrypt({ name: 'AES-GCM', iv: new Uint8Array(iv) }, key, new Uint8Array(encrypted));

      return new TextDecoder().decode(decryptedBuffer);
    } catch (e) {
      return null;
    }
  },

  async deriveKey(password: string, salt: Uint8Array) {
    const encoder = new TextEncoder();
    const keyMaterial = await crypto.subtle.importKey('raw', encoder.encode(password), { name: 'PBKDF2' }, false, ['deriveKey']);

    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: new Uint8Array(salt),
        iterations: 100000,
        hash: 'SHA-256',
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      false,
      ['encrypt', 'decrypt'],
    );
  },
};
