declare const self: ServiceWorkerGlobalScope;

/* eslint-disable @typescript-eslint/naming-convention */
export const ServiceWorkerCommand = {
  CLEAN_CACHE: 'cleanCache',
  REPLAY_RECORD: 'replayRecord',
  REPLAY_STORE: 'replayStore',
  REPLAY_LOAD: 'replayLoad',
  REPLAY_MODE: 'replayMode',
  REPLAY_STOP: 'replayStop',
};

export type ReplayWorkerMode = 'disabled' | 'record' | 'play';

// service workers only available with https
const nvgtr = navigator as Navigator;
export const getServiceWorker = (clientURL: string) => nvgtr.serviceWorker?.getRegistration(clientURL);

export const sendClientMessage = (command: string, message: string) => {
  self.clients.matchAll().then(clients => {
    clients.forEach(client => {
      client.postMessage({ command, message });
    });
  });
};
